<template>
  <div
    :class="[`scene-${nameOrDefault}`, { visible: visible }]"
    :style="{ 'background-image': `url('${backgroundOrDefault}')` }"
  >
    <slot v-if="contentVisible" />
  </div>
</template>

<script>
import EventBus from "@/classes/event-bus";
import SceneManager from "@/classes/scene-manager";

export default {
  props: ["name", "background"],
  mounted() {
    this.visible = this.contentVisible =
      SceneManager.GetActiveScene().name == this.name;

    // this.emitFirstLoadIfApplicable();

    EventBus.Subscribe(EventBus.CHANNELS.Scene, "transition", (e) => {
      this.visible = e.scene == this.name;

      if (this.visible) {
        this.contentVisible = true;

        // fire first load event if the scene was never loaded before
        // used for applying dom events etc.
        this.emitFirstLoadIfApplicable();
        this.$emit('enter');

      } else {

        if (SceneManager.GetActiveScene().name == this.name) {
          this.$emit('leave');
        }

        setTimeout(() => {
          if (!this.visible) {
            this.contentVisible = false;
          }
        }, 1500);
      }
    });
  },
  data() {
    return {
      SceneManager,
      visible: false,
      contentVisible: false,
      wasVisible: false
    };
  },
  methods: {
    emitFirstLoadIfApplicable() {
      if (!this.wasVisible) {
        this.wasVisible = true;
        this.$nextTick(() => {
          this.$emit('firstLoad');
        });
      }
    }
  },
  computed: {
    nameOrDefault() {
      if (this.name == undefined) {
        return "base-scene";
      }

      return this.name;
    },
    backgroundOrDefault() {
      if (this.background == undefined) {
        return '';
      }

      return this.background;
    },
  },
};
</script>

<style lang="scss">
[class^="scene-"] {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: black;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0;
  transition: 1.5s;
  z-index: 80;
  pointer-events: none;

  &.visible {
    opacity: 1;
    pointer-events: all;
    // z-index: 81;
  }
}
</style>