<template>
  <div :style="style" :class="[`trigger-${type}`, (pulse ? 'pulse' : '')]" @click="DoPulse">
    <slot></slot>
  </div>
</template>

<script>
import { Howl } from 'howler';

export default {
  props: ["type", "x", "y", "sound"],
  mounted() {
    this.ValidateType();
  },
  data() {
    return {
      pulse: false
    };
  },
  methods: {
    ValidateType() {
      if (this.type == undefined) {
        throw "Undefined Trigger Type";
      }

      let validTypes = ["scene", "text", "video", "image", "url", "simple"];
      if (validTypes.indexOf(this.type) == -1) {
        throw `Trigger Type ${this.type} is not a known trigger type`;
      }
    },
    DoPulse() {
      if (this.sound) {
        const clip = new Howl({
            src: [this.sound],
            volume: 1
        });

        clip.play();
      }

      this.pulse = true
      setTimeout(() => {
        this.pulse = false;
      }, 500);
    }
  },
  computed: {
    style() {
      const aspectRatio = 16 / 9;
      let s = {};

      s["margin-left"] = (this.x > 0 ? 'max' : 'min') + `(${this.x} * 1vw, ${aspectRatio * this.x} * 1vh)`;
      s["margin-top"] = (this.y > 0 ? 'max' : 'min') + `(${this.y} * 1vw, ${aspectRatio * this.y} * 1vh)`;

      return s;
    }
  }
};
</script>

<style lang="scss">
[class^="trigger-"] {
  background: white;
  @include width-ar(2.6041);
  @include height-ar(2.6041);
  // width: 50px;
  // height: 50px;
  border-radius: 50%;
  opacity: 1;
  position: absolute;
  z-index: 90;
  cursor: pointer;
  transition: 0.15s;
  left: 50%;
  top: 50%;  
  transform: translate(-50%, -50%);
  background-image: url('../assets/images/logo-theater-des-lachens.svg');
  background-position: center;
  background-size: cover;

  &.pulse {
    width: 150px;
    height: 150px;
    opacity: 0;
  }

  &:hover {
    width: 75px;
    height: 75px;
  }
}
</style>