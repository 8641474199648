<template>
    <BaseScene 
        name="porch-piano" 
        :background="ResourceManager.Get('scenes.porchpiano')"
        @leave="OnLeaveScene" 
        @enter="OnEnterScene"
    >
        <div v-for="face in faces" :key="face" :class="face" class="animated-face" @click="OnFaceClicked(face)">
            <img :src="ResourceManager.Get('team.jpg.' + face)">
            <img :src="ResourceManager.Get('team.gif.' + face)">            
        </div>

        <SceneTrigger x="0" y="7" scene="piano"/>
    </BaseScene>
</template>

<script>
import BaseScene from '../BaseScene.vue'
import SceneTrigger from '../triggers/SceneTrigger.vue';
import ResourceManager from "@/classes/resource-manager";
import EventBus from '@/classes/event-bus';
import { Howl } from 'howler';

export default {
    inject: {
        cms: {
            default: null,
        },
    },    
    components: {
        BaseScene,
        SceneTrigger
    },
    mounted() {

    },
    data() {
        return {
            ResourceManager,
            faces: [
                'torsten',
                'arek',
                'gundula',
                'andre',
                'grit',
                'mike',
                'rodrigo',
                'nicole'
            ]
        };
    },
    methods: {
        OnFaceClicked(face) {
            EventBus.Broadcast(EventBus.CHANNELS.Global, "rich-text-trigger", {
                content: this.cms.get("porchpiano", 'de').get('biography_' + face, 'de')
            });

            const sound = new Howl({
                src: [ResourceManager.Get('laughs.' + face + '.' + (Math.floor(Math.random() * 3) + 1))],
                volume: 1
            });

            sound.play();
        },
        OnEnterScene() {
            if (!this.bgm) {
                this.bgm = new Howl({
                    src: [ResourceManager.Get('ambience.foyer')],
                    loop: true,
                    volume: 1
                });
            }
    
            if (!this.bgm.playing()) {
                this.bgm.play();
            }
        },
        OnLeaveScene() {
            this.bgm.stop();
        },
             
    },
}
</script>

<style lang="scss">
.scene-porch-piano {
    .animated-face {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;

        &:hover {
            img {
                &:first-child {
                    display: none;
                }

                &:last-child {
                    display: block;
                }
            }
        }

        img {
            @include width-ar(6);
            transform: translate(-50%, -50%);
            position: absolute;
            box-shadow: -3px -3px 20px black;
            border: 1px solid #333;            
            // height: 100%;

            &:first-child {
                display: block;
            }

            &:last-child {
                display: none;
            }
        }

        &.torsten {
            @include xy-ar(-6, -10.5);
        }

        &.arek {
            @include xy-ar(6, -10.5);
        }

        &.gundula {
            @include xy-ar(-6, 0);
        }

        &.andre {
            @include xy-ar(6, 0);
        }

        &.grit {
            @include xy-ar(-18, 0);
        }

        &.mike {
            @include xy-ar(18, 0);
        }

        &.rodrigo {
            @include xy-ar(-18, -10.5);
        }

        &.nicole {
            @include xy-ar(18, -10.5);
        }
    }
}
</style>