<template>
    <BaseTrigger type="scene" :label="label" @click="OnTriggerClicked" :sound="sound">
        <slot></slot>
    </BaseTrigger>
</template>

<script>
import BaseTrigger from '../BaseTrigger.vue';
import SceneManager from "@/classes/scene-manager";

export default {
    props: ['scene', 'label', 'sound', 'delay'],
    components: {
        BaseTrigger
    },
    mounted() {
        if (this.scene == undefined) {
            console.error("Scene trigger is missing a 'scene' property");
        }
    },
    methods: {
        OnTriggerClicked() {
            if (!this.scene) {
                window.alert("Diese Szene wurde noch nicht hinterlegt");
                return;
            }

            setTimeout(() => {
                SceneManager.TransitionToScene(this.scene);
            }, this.delay ?? 0);

        }        
    }
}
</script>